<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <!--
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>
            -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <!--
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
              -->
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/login_background.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-h4 font-weight-semibold text--primary mb-2">
                    Welcome to AOO! 👋🏻
                  </p>
                  <p class="mb-2">
                    จุดเริ่มต้นของการอู้ คือระบบหลังบ้านที่ดี
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-alert
                    text
                    color="facebook"
                  >
                    การเริ่มใช้บริการจากเรา หมายความว่า คุณได้อ่านและเข้าใจ <a target="_blank" href="/policy" @click.stop class="text-decoration-underline">นโยบายความเป็นส่วนตัว</a> รวมถึงได้ตกลงยินยอมเงื่อนไขในการให้บริการของเราแล้ว
                  </v-alert>
                </v-card-text>

                <v-card-text>
                  <v-btn
                    block
                    color="facebook"
                    type="submit"
                    class="mt-8 white--text"
                    @click="loginWithFacebook"
                    :loading="isSubmitting"
                    large
                  >
                    เข้าสู่ระบบด้วย FaceBook
                  </v-btn>
                </v-card-text>
                
                <!-- divider -->
                <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text>        

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-end flex-wrap">                    
                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      :loading="isSubmitting"
                    >
                      เข้าสู่ระบบ
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    ยังไม่มีบัญชี?
                  </p>
                  <router-link :to="{name:'register', query: { redirectTo: $route.query.redirectTo }}">
                    สมัครใหม่
                  </router-link>
                </v-card-text>                      
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { asyncPost } from '../helpers/asyncAxios'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    let isSubmitting = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      const root = vm.$root

      isSubmitting.value = true

      try {
        let response = await asyncPost('/login', { email: email.value, password: password.value })

        root.$store.commit('auth/LOGIN', response)

        await root.$store.dispatch('auth/FETCH_ME')

        await root.$store.dispatch('auth/FETCH_USER_ABILITY')

        if (root.$route.query.redirectTo) window.location.href = root.$route.query.redirectTo
        else root.$router.push({ name: 'companies' })
      } catch (error) {
        root.showCommonDialog('มีปัญหา', error)
        password.value = ''
      }

      isSubmitting.value = false
    }

    const loginWithFacebook = async () => {
      const root = vm.$root

      isSubmitting.value = true

      FB.login(
        async function (response) {
          if (response.status === 'connected') {
            const { authResponse } = response
            const fbAccessToken = authResponse.accessToken

            try {
              let NewResponse = await asyncPost('/FBlogin', { accessToken: fbAccessToken })

              root.$store.commit('auth/LOGIN', NewResponse)

              await root.$store.dispatch('auth/FETCH_ME')

              await root.$store.dispatch('auth/FETCH_USER_ABILITY')

              if (root.$route.query.redirectTo) window.location.href = root.$route.query.redirectTo
              else root.$router.push({ name: 'companies' })

              isSubmitting.value = false
            } catch (error) {
              root.showCommonDialog('มีปัญหา', error)
              isSubmitting.value = false
            }
          }
        },
        { scope: 'public_profile,email' },
      )
    }

    return {
      handleFormSubmit,
      loginWithFacebook,

      isPasswordVisible,
      isSubmitting,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
